import { graphql } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import "@babel/polyfill";

import Layout from "../layouts"
import Head from "../components/head"
import SectionHero from "../components/section-hero";
import TeamMembersBlock from "../components/team-members"

import YoastSeoParts from '../fragments/seo';

class PageTemplate extends Component {
  
  render() {
    const page = this.props.data.wpPage;
    const { seo } = page
    const teamRoles = page.template.templateTeam.teamRoles;

    return (
      <Layout>
        <Head title={seo.title} description={seo.metaDesc} meta={seo} />
        
        <SectionHero section="about" title={page.title}/>

        { teamRoles &&
          teamRoles.map(({id, name, contentNodes, taxonomyRoles: { hideImages }}) => {
            return (
              <TeamMembersBlock key={id} title={name} team={contentNodes.nodes} hideImage={hideImages} />
            )
          })
        }
        
      </Layout>
    )
  }
}
export default PageTemplate

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export const query = graphql`
  query($id: String!) {
    wpPage(id: {eq: $id}) {
      title
      uri
      id
      seo {
        ...seoParts
      }
      template {
        ... on WpTemplate_MeetTheTeam {
          templateName
          templateTeam {
            fieldGroupName
            teamRoles {
              id
              name
              taxonomyRoles {
                hideImages
              }
              contentNodes {
                nodes {
                  ... on WpLeadership {
                    id
                    uri
                    title
                    link
                    content
                    cptTeamMember {
                      firstName
                      lastName
                      title
                      moreInfo
                    }
                    teamRoles {
                      nodes {
                        id
                        name
                        slug
                      }
                    }
                    featuredImage {
                      node {
                        localFile {
                          publicURL
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`