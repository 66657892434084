import React from "react"
import { graphql } from "gatsby"

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import '../css/team-members.css';

import Headshot from '../images/headshot.png';

function PopupContent({ image, name, title, content, close, hideImage, moreInfo, uri }) {
  return (
    <div id="bio1" className="bioBox maincopy">
      <button className="close" onClick={close}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">{/* Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. */}<path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z"/></svg>
      </button>

      { !hideImage &&
        <img src={image} className="flex-img" />
      }

      <h3>{name}</h3>
      <p
        style={{ marginBottom: moreInfo ? 0 : '15px' }}
      >{title}</p>
      {moreInfo && <p>{moreInfo}</p>}
      <div dangerouslySetInnerHTML={{ __html: content }} class="team-member-content"></div>
      <div className="share-team-member">
        <a href={`${uri}`} className="mainCTA">Team Member Page</a>
      </div>
    </div>
  )
}

const sortTeamMembers = () => {
  return function (a,b) {
    if(!a.cptTeamMember.lastName)
      return true;

    if(!b.cptTeamMember.lastName)
      return false;

    return a.cptTeamMember.lastName.localeCompare(b.cptTeamMember.lastName);
  }
}

const TeamMembers = ({ title, team, hideImage }) => {
  team.sort(sortTeamMembers());

  return (
    <div className="container leadershipContainer">
      <div className="grid smallGrid">
        <div className="col-sm-12">
        <h3 className="super">About</h3>        
          <h2 className="headline about">{title}</h2>
        </div>


        { team &&
          team.map(member => {
            var image = Headshot;

            const { cptTeamMember, content } = member;

            if(member.featuredImage)
              image = member.featuredImage.node.localFile.publicURL;

            return (
              <div key={member.id} className="col-6 col-sm-3 teamColumn maincopy">
                <Popup modal nested trigger={
                  <a className="popup-with-zoom-anim" href="#bio1">
                    { !hideImage &&
                      <img src={image} className="flex-img" />
                    }
                    <p><strong>{cptTeamMember.firstName} {cptTeamMember.lastName}</strong></p>
                    <p>{cptTeamMember.title}</p>
                    {cptTeamMember.moreInfo && <p>{cptTeamMember.moreInfo}</p>}
                  </a>

                } >
                {close => <PopupContent image={image} name={cptTeamMember.firstName+' '+cptTeamMember.lastName} title={cptTeamMember.title} content={content} close={close} hideImage={hideImage} moreInfo={cptTeamMember.moreInfo} uri={member.uri} />}
              </Popup>
            </div>
            )
          })
        }

      </div>

    </div>
  )
}

export default TeamMembers